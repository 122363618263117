





























































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CarFormPart from '@/components/forms/parts/CarFormPart.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import PlaceAndDateFormPart from '@/components/forms/parts/PlaceAndDateFormPart.vue'
import CarOrderView from '@/components/orderViews/CarOrderView.vue'

// INTERFACES
import { AuthorRequest, CreateCarBuybackRequest } from '@/store/types'

// STORE
import OrderModule from '@/store/modules/order'
import FleetOrderModule from '@/store/modules/fleet/order'
import DriverOrderModule from '@/store/modules/driver/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    DefaultLayout,
    ValidationProvider,
    ValidationObserver,
    CharacterCard,
    CarFormPart,
    ControlsRequestFormPart,
    PlaceAndDateFormPart,
    RequestFormPartWrapper,
    CarOrderView,
  },
})
export default class CarBuybackOrderCreate extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    count: (value: string) => value.length <= 500,
  }

  // FORM
  private form: CreateCarBuybackRequest = {
    carId: 0,
    author: {} as AuthorRequest,
    city: '',
    date: '',
    comment: '',
  }

  private isShowEditCar = true
  private isShowCarDriving = false
  private isShowComment = false
  private isSpecificCar = false

  private get operationCityList (): string[] {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return FleetOrderModule.operationCitiesDictionary
    } else {
      this.form.carId = +this.entity
      return DriverOrderModule.operationCitiesDictionary
    }
  }

  private get currentMileage () {
    return OrderModule.carSearchResponse.currentMileage
  }

  private created () {
    if (this.carId) {
      this.form.carId = +this.carId
      this.isSpecificCar = true
    } else {
      this.form.carId = 0
      this.isSpecificCar = false
    }

    if (this.role === 'fleet' || this.role === 'mechanic') {
      const payload = {
        entity: +this.entity,
        params: {
          hasRepair: true,
        },
      }

      FleetOrderModule.getOrderDictionaries(payload)
    } else {
      this.form.carId = +this.entity
      DriverOrderModule.getOrderDictionaries(+this.entity)
    }
  }

  private handleSetRegion (payload: any) {
    this.form.city = payload
  }

  private handleCloseComment () {
    this.isShowComment = false
    this.form.comment = ''
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const body: any = {
            ...this.form,
          }

          if (this.currentMileage) {
            body.currentMileage = this.currentMileage
          }

          if (this.role === 'fleet' || this.role === 'mechanic') {
            body.author.type = 'manager'

            FleetOrderModule.sendCarBuyback({ entity: +this.entity, body })
              .then((r) => {
                this.$openModal('OrderSendSuccessModal', 'big', {
                  route: {
                    name: `view-order.${r.type}`,
                    params: { orderId: r.id },
                  },
                })
                this.$router.push({ name: 'orders' })
              })
              .catch(() => {
                this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
              })
          } else {
            body.carId = +this.entity
            body.author.type = 'driver'

            DriverOrderModule.sendCarBuyback({ entity: +this.entity, body })
              .then((r) => {
                this.$openModal('OrderSendSuccessModal', 'big', {
                  route: {
                    name: `view-order.${r.type}`,
                    params: { orderId: r.id },
                  },
                })
                this.$router.push({ name: 'orders' })
              })
              .catch(() => {
                this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
              })
          }
        }
      })
  }
}
